import './Home.css';
import configData from '../config/config.json';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RegistrationSteps, StateDropDown } from 'dsoa-components';
import DsoaBanner from './DsoaBanner';

const Home = ({ setTitle = () => {} }) => {
  const navigate = useNavigate();

  setTitle('Home');

  return (
    <>
      <DsoaBanner text="Driving School of America" />
      <div className="ui main container">
        <div className="ui center aligned container">
          <div className="select-state">
            <h3>Select your state below for available courses</h3>
          </div>
        </div>
        <br />
        <RegistrationSteps step={1} />
        <br />
        <StateDropDown
          placeholder="Select a state"
          onSelectState={(st) => navigate(`/state/${st}`)}
          filterStates={configData.DSOA_ENVIRONMENT === 'production'}
        />
      </div>
    </>
  );
};

export default Home;
