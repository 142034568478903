import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext, loggedIn, isAdmin } from 'dsoa-components';
import { studentLogout } from 'dsoapi';

const Header = () => {
  const { studentName, setStudentInfo } = useContext(UserContext);
  const navigate = useNavigate();

  const onLogout = async () => {
    await studentLogout();
    localStorage.removeItem('dsoaToken');
    localStorage.removeItem('dsoaClaims');
    setStudentInfo({});
    navigate('/');
  };

  return (
    <>
      <div className="ui top fixed menu" style={{ borderRadius: '0rem' }}>
        <div className="ui container">
          <Link to="/" className="header borderless item">
            <img
              alt="logo"
              src="/logo192.png"
              style={{ height: '30px', width: '30px' }}
            />{' '}
            DSOA
          </Link>
          <div className="right menu">
            <div className="ui simple dropdown item">
              <div className="hide-mobile" style={{ paddingRight: '3px' }}>
                <i className="handshake outline icon"></i>
              </div>
              Support
              <i className="dropdown icon"></i>
              <div className="menu">
                <Link to="/contact" className="item">
                  <i className="phone icon"></i>
                  Contact
                </Link>
                <Link to="/help" className="item">
                  <i className="question circle outline icon"></i>
                  Help
                </Link>
              </div>
            </div>
            {!loggedIn() && (
              <>
                <Link to="/login" className="item">
                  <div className="hide-mobile" style={{ paddingRight: '3px' }}>
                    <i className="user outline icon"></i>
                  </div>
                  Log In
                </Link>
                <div className="item">
                  <div
                    className="ui primary button"
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate('/signup')}
                  >
                    Sign up
                  </div>
                </div>
              </>
            )}
            {studentName && (
              <>
                <div
                  className="ui simple dropdown item"
                  style={{ borderRight: '1px solid rgba(34,36,38,.1)' }}
                >
                  <i className="user circle outline icon"></i>
                  {studentName}
                  <i className="dropdown icon"></i>
                  <div className="menu">
                    <Link to="/dashboard" className="item">
                      <i className="home icon"></i>
                      Dashboard
                    </Link>
                    <Link to="/editprofile" className="item">
                      <i className="address card outline icon"></i>
                      Edit Profile
                    </Link>
                    {isAdmin() && (
                      <Link to="/loginasstudent" className="item">
                        <i className="user icon"></i>
                        Login as Student
                      </Link>
                    )}
                    <div className="item" onClick={onLogout}>
                      <i className="power off icon"></i>
                      Log Out
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: '63px' }}></div>
    </>
  );
};

export default Header;
