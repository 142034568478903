import './DsoaBanner.css';
import React from 'react';

const DsoaBanner = ({ text }) => {
  return (
    <>
      <div className="ui center aligned container">
        <div className="dsoa-banner">
          <h1>{text}</h1>
        </div>
      </div>
      <div className="ui divider"></div>
    </>
  );
};

export default DsoaBanner;
