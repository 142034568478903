import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = ({ setTitle = () => {} }) => {
  setTitle('Privacy');

  return (
    <div className="ui container">
      <div className="ui center aligned container">
        <h1>Privacy Policy</h1>
      </div>
      <p>Last updated: January 07, 2023</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service.
      </p>
      <p>
        We use Your Personal data to provide the Service. By using the Service,
        You agree to the collection and use of information in accordance with
        this Privacy Policy.
      </p>

      <h3>Personal Data</h3>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>
          <p>Email address</p>
        </li>
        <li>
          <p>First name and last name</p>
        </li>
        <li>
          <p>Phone number</p>
        </li>
        <li>
          <p>Address, State, Province, ZIP/Postal code, City</p>
        </li>
      </ul>
      <h3>3rd Party Services</h3>
      <p>
        If you create an account or log into the Services using your Google
        credentials, we receive your name and email address as permitted by your
        Google profile settings in order to authenticate you.
      </p>

      <h3>Use of Your Personal Data</h3>
      <p>We may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          <p>
            <strong>To contact You:</strong> To contact You by email, telephone
            calls, related to the functionalities or services, when necessary or
            reasonable for their implementation.
          </p>
        </li>
        <li>
          <p>
            <strong>To provide You</strong> with information about your course
            registration and course completions.
          </p>
        </li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul>
        <li>
          <strong>With Service Providers:</strong> Depending on the course you
          take, we may share Your personal information with the states or court
          houses to submit your completion status.
        </li>
      </ul>

      <h3>Changes to this Privacy Policy</h3>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, You can{' '}
        <Link to="/contact">contact us</Link>.
      </p>
    </div>
  );
};

export default Privacy;
