import React from 'react';
import { Link } from 'react-router-dom';

const CourseCards = ({ courses }) => {
  const renderBullets = (course) => {
    return course.details.bullets.map((bullet) => {
      return (
        <div className="item" key={`${bullet}`}>
          {bullet}
        </div>
      );
    });
  };

  const renderedList = courses.map((course) => {
    return (
      <Link
        to={`/course/${course.abbreviation}`}
        className="ui card"
        key={course.abbreviation}
      >
        <div className="content">
          <div className="center aligned header">{course.displayName}</div>
        </div>
        <div className="content">
          <div className="description">
            <div className="ui bulleted list">{renderBullets(course)}</div>
          </div>
        </div>
        <div className="extra content">
          {`$${parseFloat(course.price).toFixed(2)}`}
          <div className="right floated">{course.details.duration}</div>
        </div>
      </Link>
    );
  });
  return <div className="ui three stackable cards">{renderedList}</div>;
};

export default CourseCards;
